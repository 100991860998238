import React from 'react';

import Layout from 'components/layout';
import SEO from 'components/seo';
import { useI18n } from 'services/i18n';
import { PrimaryTitle, BlockTitle } from 'components/texts';
import { Container } from 'styles/grid';
import { Text, BigText } from 'components/texts';
import { Link } from 'gatsby-plugin-intl';
import styled from 'styled-components';

const StyledSubtitle = styled(BigText)`
  font-weight: bold;
  margin-bottom: 0;
`;

const StyledText = styled(Text)`
  margin-top: 0;
`;

const StyledList = styled.ul`
  margin-left: 2rem;
  list-style: disc;
  margin-bottom: 20px;
`;

const PrivacyPolicy = () => {
  const [t, language] = useI18n();

  return (
    <Layout>
      <SEO
        title={t('seo.title.privacy-policy')}
        lang={language}
        description={t('seo.description.privacy-policy')}
      />
      <section>
        <Container>
          <PrimaryTitle>{t('privacy-policy.block-one.title')}</PrimaryTitle>
          <br />
          <StyledText>{t('privacy-policy.block-one.paragraph-one')}</StyledText>
          <StyledText>{t('privacy-policy.block-one.paragraph-two')}</StyledText>
          <StyledText>{t('privacy-policy.block-one.paragraph-three')}</StyledText>
          <br />
          <BlockTitle id="data-collect">{t('privacy-policy.block-two.title')}</BlockTitle>
          <StyledSubtitle>{t('privacy-policy.block-two.list-item-1.title')}</StyledSubtitle>
          <StyledText>{t('privacy-policy.block-two.list-item-1.paragraph-one')}</StyledText>
          <StyledText>{t('privacy-policy.block-two.list-item-1.paragraph-two')}</StyledText>
          <StyledText>{t('privacy-policy.block-two.list-item-1.paragraph-three')}</StyledText>
          <StyledText>{t('privacy-policy.block-two.list-item-1.paragraph-four')}</StyledText>
          <StyledText>{t('privacy-policy.block-two.list-item-1.paragraph-five')}</StyledText>
          <StyledText>{t('privacy-policy.block-two.list-item-1.paragraph-six')}</StyledText>
          <StyledSubtitle>{t('privacy-policy.block-two.list-item-2.title')}</StyledSubtitle>
          <StyledText>{t('privacy-policy.block-two.list-item-2.paragraph-one')}</StyledText>
          <StyledText>{t('privacy-policy.block-two.list-item-2.paragraph-two')}</StyledText>
          <br />
          <BlockTitle>{t('privacy-policy.block-three.title')}</BlockTitle>
          <StyledSubtitle>{t('privacy-policy.block-three.list-item-1.title')}</StyledSubtitle>
          <StyledText>{t('privacy-policy.block-three.list-item-1.paragraph-one')}</StyledText>
          <StyledText>{t('privacy-policy.block-three.list-item-1.paragraph-two')}</StyledText>
          <StyledText>{t('privacy-policy.block-three.list-item-1.paragraph-three')}</StyledText>
          <StyledSubtitle>{t('privacy-policy.block-three.list-item-2.title')}</StyledSubtitle>
          <StyledText>{t('privacy-policy.block-three.list-item-2.paragraph-one')}</StyledText>
          <br />
          <BlockTitle>{t('privacy-policy.block-four.title')}</BlockTitle>
          <StyledSubtitle>{t('privacy-policy.block-four.list-item-1.title')}</StyledSubtitle>
          <StyledText>{t('privacy-policy.block-four.list-item-1.paragraph-one')}</StyledText>
          <StyledText>{t('privacy-policy.block-four.list-item-1.paragraph-two')}</StyledText>
          <StyledText>{t('privacy-policy.block-four.list-item-1.paragraph-three')}</StyledText>
          <StyledText>{t('privacy-policy.block-four.list-item-1.paragraph-four')}</StyledText>
          <StyledSubtitle>{t('privacy-policy.block-four.list-item-2.title')}</StyledSubtitle>
          <StyledText>{t('privacy-policy.block-four.list-item-2.paragraph-one')}</StyledText>
          <StyledText>{t('privacy-policy.block-four.list-item-2.paragraph-two')}</StyledText>
          <StyledText>{t('privacy-policy.block-four.list-item-2.paragraph-three')}</StyledText>
          <StyledText>{t('privacy-policy.block-four.list-item-2.paragraph-four')}</StyledText>
          <br />
          <BlockTitle>{t('privacy-policy.block-five.title')}</BlockTitle>
          <StyledSubtitle>{t('privacy-policy.block-five.list-item-1.title')}</StyledSubtitle>
          <StyledText>{t('privacy-policy.block-five.list-item-1.paragraph-one')}</StyledText>
          <StyledText>{t('privacy-policy.block-five.list-item-1.paragraph-two')}</StyledText>
          <StyledSubtitle>{t('privacy-policy.block-five.list-item-2.title')}</StyledSubtitle>
          <StyledText>{t('privacy-policy.block-five.list-item-2.paragraph-one')}</StyledText>
          <StyledText style={{ marginBottom: '0' }}>
            {t('privacy-policy.block-five.list-item-2.paragraph-two')}
          </StyledText>
          <Link style={{ marginBottom: '20px', display: 'inline-block' }} to="/cookie-policy">
            {t('privacy-policy.block-five.list-item-2.link')}
          </Link>
          <br /> <br />
          <BlockTitle>{t('privacy-policy.block-six.title')}</BlockTitle>
          <StyledSubtitle></StyledSubtitle>
          <StyledText>{t('privacy-policy.block-six.paragraph-one')}</StyledText>
          <StyledText>{t('privacy-policy.block-six.paragraph-two')}</StyledText>
          <StyledList>
            <li>{t('privacy-policy.block-six.li-1')}</li>
            <li>{t('privacy-policy.block-six.li-2')}</li>
            <li>{t('privacy-policy.block-six.li-3')}</li>
            <li>{t('privacy-policy.block-six.li-4')}</li>
            <li>{t('privacy-policy.block-six.li-5')}</li>
            <li>{t('privacy-policy.block-six.li-6')}</li>
          </StyledList>
          <br />
          <BlockTitle>{t('privacy-policy.block-seven.title')}</BlockTitle>
          <StyledSubtitle></StyledSubtitle>
          <StyledText>{t('privacy-policy.block-seven.paragraph-one')}</StyledText>
          <br />
          <BlockTitle>{t('privacy-policy.block-eight.title')}</BlockTitle>
          <StyledSubtitle></StyledSubtitle>
          <StyledText>{t('privacy-policy.block-eight.paragraph-one')}</StyledText>
          <StyledText>{t('privacy-policy.block-eight.paragraph-two')}</StyledText>
          <StyledText>{t('privacy-policy.block-eight.paragraph-three')}</StyledText>
          <StyledText>{t('privacy-policy.block-eight.paragraph-four')}</StyledText>
        </Container>
      </section>
    </Layout>
  );
};

export default PrivacyPolicy;
